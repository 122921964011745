import { useState, useEffect, useCallback } from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { usePatientAuth } from "context/patient-auth";
import { useAdminContent } from "context/admin-content";

export function useRiskReport() {
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [reportHtml, setReportHtml] = useState("");
  const [reportPdf, setReportPdf] = useState("");
  const [reportData, setReportData] = useState<any>({});
  const { logout, isAuthenticated } = usePatientAuth();
  const adminContent = useAdminContent();

  const fetchRiskReport = useCallback(async () => {
    if (!adminContent) {
      return;
    }

    setError(false);
    setLoading(true);

    const patientId = localStorage.getItem("ciq_id");
    const idToken = localStorage.getItem("id_token");

    const template = adminContent.riskAssessmentTemplate;

    try {
      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/reports/show?person_id=${patientId}&template_name=${template}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          logout();
        } else {
          setError(true);
        }
        return;
      }

      const riskReport = await response.json();

      setReportHtml(riskReport.report_html);
      setReportPdf(riskReport.report_pdf);
      setReportData(riskReport.report_data);
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [adminContent, logout]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchRiskReport();
    }
  }, [fetchRiskReport, isAuthenticated]);

  return {
    error,
    isLoading,
    reportPdf,
    reportHtml,
    reportData,
    fetchRiskReport,
  };
}
