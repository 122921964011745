import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  ReactElement,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
// @ts-ignore - auth0-js is not typed
import { default as auth0 } from "auth0-js";
import { getProtocolHost } from "helpers";
import { jwtDecode, JwtPayload } from "jwt-decode";
import {
  AUTH0_DOMAIN,
  AUTH0_PATIENTS_CLIENT_ID,
  AUTH0_PATIENTS_CONNECTION,
} from "env-vars";
import * as Sentry from "@sentry/react";
import { SurveyKind } from "../survey";
import { Span } from "@sentry/tracing";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_PATIENTS_CLIENT_ID,
  redirectUri: getProtocolHost(),
  responseType: "token id_token",
  scope: "openid profile email",
});

enum EmailLinkError {
  GENERAL_ERROR = "general",
  UNAUTHORIZED = "unauthorized",
  NO_LOCATION_ID = "no_location_id",
}

enum EmailPasswordError {
  ACCESS_DENIED = "access_denied",
  GENERAL_ERROR = "general",
}

// eslint-disable-next-line react-refresh/only-export-components
export const emailLinkErrorMessage: Record<EmailLinkError, string> = {
  [EmailLinkError.GENERAL_ERROR]:
    "There was an error signing you in. Please try again.",
  [EmailLinkError.UNAUTHORIZED]:
    "The link has expired after 15 minutes or has already been used. Please enter your email to receive a new link.",
  [EmailLinkError.NO_LOCATION_ID]:
    "Your account is missing a sign-up location. Please use the link you received to create your account, or reach out to your health system for support.",
};

// eslint-disable-next-line react-refresh/only-export-components
export const emailPasswordErrorMsg: Record<EmailPasswordError, string> = {
  [EmailPasswordError.ACCESS_DENIED]:
    "Invalid email or password. Please try again.",
  [EmailPasswordError.GENERAL_ERROR]:
    "Error submitting credentials. Please try again.",
};

export type PatientAuthContextType = {
  isLoading: boolean;
  isEmailSent: boolean;
  isSubmitting: boolean;
  emailAddress: string;
  isAuthenticated: boolean;
  passwordlessError: boolean;
  emailLinkError: EmailLinkError | undefined;
  emailPasswordError: EmailPasswordError | undefined;
  forgotPasswordError: boolean;
  logout: () => void;
  requestPasswordReset: (email: string) => void;
  passwordResetSuccess: boolean | undefined;
  setSubmitting: (value: boolean) => void;
  sendMagicLink: (email: string) => void;
  setPasswordlessError: (value: boolean) => void;
  setEmailLinkError: (value: EmailLinkError | undefined) => void;
  setEmailPasswordError: (value: EmailPasswordError | undefined) => void;
  setForgotPasswordError: (value: boolean) => void;
  submitCredentials: (email: string, password: string) => void;
  requestForgotPasswordReset: (email: string) => void;
};

export const PatientAuthContext = createContext<
  PatientAuthContextType | undefined
>(undefined);

const logError = (message: string, err: unknown) => {
  Sentry.captureMessage(message, { extra: { err } });
};

type AuthResult = {
  idToken: string;
  idTokenPayload: {
    app_metadata: {
      location_id: string;
      ciq_id: string;
    };
    email: string;
  };
  appState: string;
};

type Props = {
  children: ReactElement;
};

// for params that need to be preserved through auth0 login redirect
type LaunchState = {
  surveyKind?: SurveyKind;
};

function encodeLaunchState(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const state: LaunchState = {
    surveyKind: urlParams.get("kind") as SurveyKind,
  };
  return btoa(JSON.stringify(state));
}

function decodeLaunchState(state: string): LaunchState | undefined {
  return state ? (JSON.parse(atob(state)) as LaunchState) : undefined;
}

export function PatientAuthProvider({ children }: Props) {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [isEmailSent, setEmailSent] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [passwordlessError, setPasswordlessError] = useState(false);
  const [emailPasswordError, setEmailPasswordError] = useState<
    EmailPasswordError | undefined
  >(undefined);
  const [emailLinkError, setEmailLinkError] = useState<
    EmailLinkError | undefined
  >(undefined);
  const [forgotPasswordError, setForgotPasswordError] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<
    boolean | undefined
  >(undefined);

  const handleTokenAndRedirect = useCallback(() => {
    const idTokenExp = localStorage.getItem("id_token_exp");
    const idToken = localStorage.getItem("id_token");

    const isTokenValid =
      Boolean(idToken) &&
      Boolean(idTokenExp) &&
      Number(idTokenExp) > Date.now();

    setAuthenticated(isTokenValid);

    if (!isTokenValid) {
      localStorage.clear();
      setLoading(false);

      return;
    }

    const locationId = localStorage.getItem("location_id");
    const ciqId = localStorage.getItem("ciq_id");

    if (!locationId) {
      setEmailLinkError(EmailLinkError.NO_LOCATION_ID);
      localStorage.clear();
      setAuthenticated(false);
      setLoading(false);

      history.push("/login/passwordless");

      return;
    }

    if (!ciqId) {
      history.push("/login/info");
    }

    setLoading(false);
  }, [history]);

  useEffect(() => {
    webAuth.parseHash((error: unknown, authResult: AuthResult) => {
      // This code runs on page load. Only record a transaction if there is a login result.
      let transaction: Span | undefined = undefined;
      if (error || authResult) {
        transaction = Sentry.startTransaction({ name: "Process AuthResult" });
        Sentry.getCurrentHub().configureScope((scope) =>
          scope.setSpan(transaction)
        );
      }

      if (error) {
        // @ts-ignore no types
        const errorType = error.error;
        // @ts-ignore no types
        const errorDesc = error.errorDescription;

        if (
          errorType === EmailLinkError.UNAUTHORIZED &&
          errorDesc.includes("Wrong email or verification code")
        ) {
          setEmailLinkError(EmailLinkError.UNAUTHORIZED);
        } else {
          setEmailLinkError(EmailLinkError.GENERAL_ERROR);
        }

        console.error(`Error from parseHash. error: "${errorType}", desc: "${errorDesc}"`);
        localStorage.clear();
        setAuthenticated(false);
        setLoading(false);

        transaction?.setStatus('unauthenticated'); 
        transaction?.finish();

        return;
      }

      const idToken = authResult?.idToken;
      const idTokenPayload = authResult?.idTokenPayload;

      if (idToken && idTokenPayload) {
        localStorage.setItem("id_token", authResult.idToken);

        const { exp = 0 } = jwtDecode<JwtPayload>(idToken);

        const calculatedExpiry = (exp * 1000).toString();

        localStorage.setItem("id_token_exp", calculatedExpiry);

        const appMetaData = authResult?.idTokenPayload?.app_metadata;
        const email = authResult?.idTokenPayload.email;
        const locationId = appMetaData?.location_id;
        const ciqId = appMetaData?.ciq_id;

        if (locationId) {
          localStorage.setItem("location_id", locationId);
        }
        if (ciqId) {
          localStorage.setItem("ciq_id", ciqId);
        }
        if (email) {
          localStorage.setItem("ciq_email", email);
        }

        const launchState = decodeLaunchState(authResult?.appState);
        const launchSurveyKind = launchState?.surveyKind;
        if (launchSurveyKind) {
          localStorage.setItem("launch_survey_kind", String(launchSurveyKind));
        }

        // Clears hash in URL produced by Auth0 redirect
        if (window.location.hash) {
          history.replace({
            pathname: location.pathname,
            search: location.search,
          });
        }

        transaction?.setStatus('ok');
        transaction?.finish();
      }

      handleTokenAndRedirect();
    });
  }, [history, location.search, location.pathname, handleTokenAndRedirect]);

  const sendMagicLink = useCallback(
    (email: string) => {
      setEmailSent(false);

      const urlParams = new URLSearchParams(location.search);
      // only used for new users
      const qs_location_id = urlParams.get("location_id");

      webAuth.passwordlessStart(
        {
          connection: "email",
          send: "link",
          email: email,
          authParams: {
            qs_location_id: qs_location_id,
            appState: encodeLaunchState(),
          },
        },
        function (err: Error) {
          setPasswordlessError(Boolean(err));
          setEmailSent(true);
          setSubmitting(false);

          if (err) {
            logError("Passwordless start error", err);
          } else {
            setEmailAddress(email);

            history.push("/login/passwordless/confirm");
          }
        }
      );
    },
    [location, history]
  );

  const submitCredentials = useCallback((email: string, password: string) => {
    webAuth.login(
      {
        realm: AUTH0_PATIENTS_CONNECTION,
        email: email,
        password: password,
        appState: encodeLaunchState(),
      },
      function (err: Error) {
        if (err) {
          setSubmitting(false);
          // handle login error result
          // @ts-ignore no error types
          if (err.code === EmailPasswordError.ACCESS_DENIED) {
            setEmailPasswordError(EmailPasswordError.ACCESS_DENIED);
          } else {
            setEmailPasswordError(EmailPasswordError.GENERAL_ERROR);
            logError("Login error", err);
          }
        } else {
          // login should redirect to the auth0 and then back to the app
          console.debug("expected redirect, but nothing happened");
        }
      }
    );
  }, []);

  const logout = useCallback(() => {
    localStorage.clear();

    // End auth0 session. this doesn't affect app behavior, just here for completeness
    webAuth.logout();
  }, []);

  const requestPasswordReset = useCallback((email: string) => {
    webAuth.changePassword(
      {
        connection: AUTH0_PATIENTS_CONNECTION,
        email,
      },
      function (err: Error) {
        if (err) {
          logError("Error requesting password reset", err);
          setPasswordResetSuccess(false);
        } else {
          setPasswordResetSuccess(true);
        }
      }
    );
  }, []);

  const requestForgotPasswordReset = useCallback(
    (email: string) => {
      setSubmitting(true);
      setEmailSent(false);

      webAuth.changePassword(
        {
          connection: AUTH0_PATIENTS_CONNECTION,
          email,
        },
        function (err: Error) {
          setEmailSent(true);
          setSubmitting(false);

          if (err) {
            setForgotPasswordError(true);
            logError("Error requesting password reset", err);
          } else {
            setForgotPasswordError(false);
            setEmailAddress(email);
            history.push("/login/forgot/confirm");
          }
        }
      );
    },
    [history, setEmailAddress, setForgotPasswordError, setSubmitting]
  );

  const contextProps = useMemo(
    () => ({
      logout,
      isLoading,
      isEmailSent,
      isSubmitting,
      emailAddress,
      sendMagicLink,
      setSubmitting,
      emailLinkError,
      submitCredentials,
      isAuthenticated,
      passwordlessError,
      emailPasswordError,
      setEmailLinkError,
      setPasswordlessError,
      setEmailPasswordError,
      requestPasswordReset,
      passwordResetSuccess,
      requestForgotPasswordReset,
      forgotPasswordError,
      setForgotPasswordError,
    }),
    [
      logout,
      isLoading,
      isEmailSent,
      emailAddress,
      isSubmitting,
      sendMagicLink,
      setSubmitting,
      emailLinkError,
      submitCredentials,
      isAuthenticated,
      passwordlessError,
      emailPasswordError,
      requestPasswordReset,
      passwordResetSuccess,
      requestForgotPasswordReset,
      forgotPasswordError,
      setForgotPasswordError,
    ]
  );

  return (
    <PatientAuthContext.Provider value={contextProps}>
      {children}
    </PatientAuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const usePatientAuth = () => {
  const context = useContext(PatientAuthContext);

  if (context === undefined) {
    throw new Error("usePatientAuth must be used within a PatientAuthProvider");
  }
  return context;
};
