import { ReactElement } from "react";
import { useLocation, Link } from "react-router-dom";
import { Typography } from "storybook/components/typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { HeaderGraphic } from "./header-graphic";
import { Logo } from "components/logo";
import {
  Header,
  Content,
  LogoIconContainer,
  BackIconContainer,
  MainContainer,
  FooterContainer,
  InnerContainer,
  ContentContainer,
  GraphicContainer,
} from "./account-layout-styles";
import { EmailGraphic } from "./email-graphic";
import { SecurityGraphic } from "./security-graphic";

type Props = {
  children: ReactElement;
};

const currentYear = new Date().getFullYear();

function AccountLayoutGraphic() {
  const location = useLocation();

  const isConfirmPage = location.pathname.includes("/confirm");
  const isMatchingPage = location.pathname.includes("/info");
  const isForgotPage = location.pathname.includes("/forgot");
  const isForgotConfirmPage = location.pathname.includes("/forgot/confirm");

  if (isMatchingPage) {
    return null;
  }

  if (isConfirmPage) {
    return (
      <GraphicContainer>
        <EmailGraphic />
      </GraphicContainer>
    );
  }

  if (isForgotPage) {
    return (
      <GraphicContainer>
        {isForgotConfirmPage ? <EmailGraphic /> : <SecurityGraphic />}
      </GraphicContainer>
    );
  }

  return (
    <GraphicContainer>
      <HeaderGraphic />
    </GraphicContainer>
  );
}

function AccountLayoutHeader() {
  const location = useLocation();

  const isForgotPage = location.pathname.includes("/forgot");
  const isForgotConfirmPage = location.pathname.includes("/forgot/confirm");

  if (isForgotPage && !isForgotConfirmPage) {
    return (
      <Link to="/login/password">
        <BackIconContainer>
          <ChevronLeftIcon />
        </BackIconContainer>
      </Link>
    );
  }

  return (
    <LogoIconContainer>
      <Logo />
    </LogoIconContainer>
  );
}

export function AccountLayout({ children }: Props) {
  return (
    <>
      <MainContainer>
        <ContentContainer>
          <InnerContainer>
            <Header>
              <AccountLayoutHeader />
            </Header>
            <AccountLayoutGraphic />
            <Content>{children}</Content>
          </InnerContainer>
        </ContentContainer>
      </MainContainer>
      <FooterContainer>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ciqBrandLight"
          component="p"
        >
          © {currentYear} CancerIQ. All Rights Reserved.{" "}
          <a
            href="https://www.canceriq.com/terms-of-use-agreement"
            target="_blank"
          >
            Terms of Service
          </a>
        </Typography>
      </FooterContainer>
    </>
  );
}
