import React from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Typography } from "storybook/components/typography";
import { Tooltip } from "storybook/components/tooltip";
import { Accordion } from "storybook/components/accordion";
import { Loading } from "components/loading";
import { useAdminContent } from "context/admin-content";
import { useScrollbar } from "context/scrollbar";
import { useRiskReport } from "../../use-risk-report";

import {
  HeadingContainer,
  SectionNumber,
  SectionHeading,
  SectionContent,
  RiskSectionContainer,
  RiskSection,
  RiskList,
  RiskListItemIncreased,
  RiskListItemDecreased,
  TooltipContainer,
} from "./styles";

import { ReportContainer, Content } from "../../styles";

const IncreasedRiskTooltip = () => {
  return (
    <>
      <Typography theme="default" variant="h6" color="black">
        <b>Hereditary cancer</b>
      </Typography>
      <br />
      <Typography theme="default" variant="body1" color="black">
        Hereditary cancer is a type of cancer that runs in some families.
        Collaborate with our specialist to create a personalized cancer
        prevention plan tailored to your hereditary cancer risk.
      </Typography>
    </>
  );
};

export const RiskResults = () => {
  const adminData = useAdminContent();
  const { scrollbarVisible, scrollbarWidth } = useScrollbar();
  const { reportHtml, reportData, isLoading: reportLoading } = useRiskReport();

  const isLoading = adminData?.isLoading || reportLoading;

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  const {
    has_cancer: hasCancer,
    cancers_and_dx,
    has_family_cancer: hasFamilyCancer,
    family_cancer_history_hash,
    lifestyle,
    vitals,
    reproductive_risk_factors,
    increased_risk_detected,
    is_female,
    mri_screening_eligible,
    breast_screening_eligible,
    cervical_screening_eligible,
    lung_screening_eligible,
    colon_screening_eligible,
    prostate_screening_eligible,
    tc8_mri_enabled,
    risk_scores,
    normalized_tc_scores,
    meets_criteria_hnpcc,
    meets_criteria_hboc,
  } = reportData;

  const cancers: Array<any> = cancers_and_dx;
  const familyCancerHistory: Array<any> = family_cancer_history_hash;

  const hasExerciseRisk =
    lifestyle.exercise_days && lifestyle.exercise_total < 75;

  const hasSmokingRisk =
    lifestyle.current_smoker_risk_assessment || lifestyle.pack_years > 0;

  const hasVegDietaryRisk =
    lifestyle.veg_servings !== null &&
    lifestyle.veg_servings_s !== "More Than 4" &&
    lifestyle.veg_servings_s !== "N/A" &&
    lifestyle.veg_servings < 4;

  const hasRedMeatDietaryRisk = lifestyle.red_meat_servings > 6;

  const hasBmiRisk = vitals.bmi >= 25;

  const hasStandardOfCareScreeningRisk =
    (is_female &&
      (mri_screening_eligible ||
        breast_screening_eligible ||
        cervical_screening_eligible)) ||
    lung_screening_eligible ||
    colon_screening_eligible ||
    (!is_female && prostate_screening_eligible);

  const hasPersonalBreastCancerRisk =
    tc8_mri_enabled && risk_scores.tc.v8_lifetime > 0;

  const hasHereditaryCancerRisk = meets_criteria_hnpcc || meets_criteria_hboc;

  const hasIncreasedRisk =
    hasCancer ||
    hasFamilyCancer ||
    hasExerciseRisk ||
    hasSmokingRisk ||
    lifestyle.exceeds_alcohol_guidelines ||
    hasVegDietaryRisk ||
    hasRedMeatDietaryRisk ||
    hasBmiRisk ||
    hasStandardOfCareScreeningRisk ||
    hasPersonalBreastCancerRisk ||
    hasHereditaryCancerRisk;

  const hasDecreasedExerciseRisk =
    lifestyle.exercise_days !== null && lifestyle.exercise_total >= 75;

  const hasDecreasedSmokingRisk =
    lifestyle.current_smoker_risk_assessment !== null &&
    !lifestyle.current_smoker_risk_assessment;

  const hasDecreasedAlcoholRisk =
    lifestyle.current_drinker_risk_assessment !== null &&
    !lifestyle.current_drinker_risk_assessment;

  const hasDecreasedVegDietaryRisk =
    lifestyle.veg_servings_s === "More Than 4" || lifestyle.veg_servings >= 4;

  const hasDecreasedRedMeatDietaryRisk =
    lifestyle.red_meat_servings !== null &&
    lifestyle.red_meat_servings >= 0 &&
    lifestyle.red_meat_servings <= 6;

  const hasDecreasedBmiRisk =
    vitals.bmi !== null && vitals.bmi >= 18.5 && vitals.bmi <= 24.9;

  const hasDecreasedRisk =
    reproductive_risk_factors.age_first_birth ||
    !increased_risk_detected ||
    hasDecreasedExerciseRisk ||
    hasDecreasedSmokingRisk ||
    hasDecreasedAlcoholRisk ||
    hasDecreasedVegDietaryRisk ||
    hasDecreasedRedMeatDietaryRisk ||
    hasDecreasedBmiRisk;

  const Report = () => (
    <ReportContainer role="article">
      {HtmlParser(reportHtml, {
        transform: (node: any) =>
          node.attribs?.id === "page2" ? null : undefined,
      })}
    </ReportContainer>
  );

  return (
    <>
      <HeadingContainer data-testid="PatientResultsDisplay2">
        <SectionHeading>
          <SectionNumber variant="h4" color="governorBay">
            1
          </SectionNumber>
          <Typography theme="default" variant="body1" color="governorBay">
            Risk Results
          </Typography>
        </SectionHeading>
      </HeadingContainer>
      <SectionContent
        $scrollbarWidth={scrollbarWidth}
        $scrollbarVisible={scrollbarVisible}
      >
        <Typography theme="default" variant="h4" color="black">
          <b>Your personalized risk assessment</b>
        </Typography>
        {increased_risk_detected ? (
          <Typography theme="default" variant="h4" color="monza">
            <b>Increased Risk Detected</b>
          </Typography>
        ) : (
          <Typography theme="default" variant="h4" color="apple">
            <b>Average Risk Detected</b>
          </Typography>
        )}
        {increased_risk_detected ? (
          <Typography theme="default" variant="body1" color="black">
            Thank you for completing the assessment!
            <br />
            <b>
              Based on your personal and family history, we recommend that you
              meet with our specialist to identify ways to manage and reduce
              your cancer risk.
            </b>
            <TooltipContainer>
              <Tooltip
                placement="left"
                theme="default"
                title={<IncreasedRiskTooltip />}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </TooltipContainer>
          </Typography>
        ) : (
          <Typography theme="default" variant="body1" color="black">
            Bring these results to your next doctor's appointment to create a
            personalized cancer prevention plan.
          </Typography>
        )}
        <RiskSectionContainer>
          <RiskSection>
            <Typography theme="default" variant="body1" color="ciqBrandDark">
              Factors <b>increasing</b> your cancer risk
            </Typography>
            {hasIncreasedRisk ? (
              <RiskList>
                {hasCancer &&
                  cancers.map((cancer) => (
                    <RiskListItemIncreased key={cancer.type}>
                      {cancer.type} Cancer
                      {cancer.age > 0 && ` at age ${cancer.age}`}
                    </RiskListItemIncreased>
                  ))}
                {hasFamilyCancer &&
                  familyCancerHistory.map((familyCancer, index) => (
                    <RiskListItemIncreased key={index}>
                      {`${familyCancer.relationship} had ${
                        familyCancer.cancer_site
                      } Cancer ${
                        familyCancer.age_of_dx &&
                        `at age ${familyCancer.age_of_dx}`
                      }`}
                    </RiskListItemIncreased>
                  ))}
                {hasExerciseRisk && (
                  <RiskListItemIncreased>
                    Total exercise of {lifestyle.exercise_total} minutes/week
                    below the recommended 75 minutes/week
                  </RiskListItemIncreased>
                )}
                {hasSmokingRisk && (
                  <RiskListItemIncreased>
                    {lifestyle.pack_years > 0
                      ? `Smoker: has smoked for ${lifestyle.pack_years} pack years`
                      : "Smoker"}
                  </RiskListItemIncreased>
                )}
                {lifestyle.exceeds_alcohol_guidelines && (
                  <RiskListItemIncreased>
                    {`Drinking exceeds the recommended maximum of ${lifestyle.recommended_drinks} drinks per week`}
                  </RiskListItemIncreased>
                )}
                {hasVegDietaryRisk && (
                  <RiskListItemIncreased>
                    {`Fruit/Veg: only ${lifestyle.veg_servings} servings perday`}
                  </RiskListItemIncreased>
                )}
                {hasRedMeatDietaryRisk && (
                  <RiskListItemIncreased>
                    {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                  </RiskListItemIncreased>
                )}
                {hasBmiRisk && (
                  <RiskListItemIncreased>
                    {`BMI: ${vitals.bmi}`}
                  </RiskListItemIncreased>
                )}
                {is_female && mri_screening_eligible && (
                  <RiskListItemIncreased>
                    You're overdue for an MRI
                  </RiskListItemIncreased>
                )}
                {is_female && cervical_screening_eligible && (
                  <RiskListItemIncreased>
                    You're overdue for a Cervical Screening (Pap Smear)
                  </RiskListItemIncreased>
                )}
                {is_female && breast_screening_eligible && (
                  <RiskListItemIncreased>
                    You're overdue for a Mammogram
                  </RiskListItemIncreased>
                )}
                {lung_screening_eligible && (
                  <RiskListItemIncreased>
                    You're overdue for a Low Dose Lung CT
                  </RiskListItemIncreased>
                )}
                {colon_screening_eligible && (
                  <RiskListItemIncreased>
                    You're overdue for a Colonoscopy
                  </RiskListItemIncreased>
                )}
                {!is_female && prostate_screening_eligible && (
                  <RiskListItemIncreased>
                    You have had no prior PSA Test or it has been &gt;1 year
                    since your last PSA Test
                  </RiskListItemIncreased>
                )}
                {tc8_mri_enabled && risk_scores.tc.v8_lifetime > 0 && (
                  <RiskListItemIncreased>
                    {`Tyrer-Cuzick 8 Lifetime: ${normalized_tc_scores.tc8_lifetime}`}
                  </RiskListItemIncreased>
                )}
                {meets_criteria_hnpcc && (
                  <RiskListItemIncreased>
                    Potential Hereditary Colorectal Cancer Mutation
                  </RiskListItemIncreased>
                )}
                {meets_criteria_hboc && (
                  <RiskListItemIncreased>
                    Potential Hereditary Breast and Ovarian Cancer Syndrome
                    Mutation
                  </RiskListItemIncreased>
                )}
              </RiskList>
            ) : (
              <p>N/A</p>
            )}
          </RiskSection>
          <RiskSection>
            <Typography theme="default" variant="body1" color="ciqBrandDark">
              Factors <b>decreasing</b> your cancer risk
            </Typography>
            {hasDecreasedRisk ? (
              <RiskList>
                {Boolean(reproductive_risk_factors.age_first_birth) && (
                  <RiskListItemDecreased>
                    You have given birth
                  </RiskListItemDecreased>
                )}
                {!increased_risk_detected && (
                  <RiskListItemDecreased>
                    Up to date on recommended cancer screenings
                  </RiskListItemDecreased>
                )}
                {hasDecreasedExerciseRisk && (
                  <RiskListItemDecreased>
                    You are physically active
                  </RiskListItemDecreased>
                )}
                {hasDecreasedSmokingRisk && (
                  <RiskListItemDecreased>
                    You do not smoke
                  </RiskListItemDecreased>
                )}
                {hasDecreasedAlcoholRisk && (
                  <RiskListItemDecreased>
                    You do not drink alcohol
                  </RiskListItemDecreased>
                )}
                {lifestyle.veg_servings >= 4 && (
                  <RiskListItemDecreased>
                    {`Fruit/Veg: ${lifestyle.veg_servings} servings per day`}
                  </RiskListItemDecreased>
                )}
                {lifestyle.veg_servings_s === "More Than 4" && (
                  <RiskListItemDecreased>
                    Fruit/Veg: More than 4 servings/day
                  </RiskListItemDecreased>
                )}
                {hasDecreasedRedMeatDietaryRisk && (
                  <RiskListItemDecreased>
                    {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                  </RiskListItemDecreased>
                )}
                {hasDecreasedBmiRisk && (
                  <RiskListItemDecreased>
                    {`BMI: ${vitals.bmi}`}
                  </RiskListItemDecreased>
                )}
              </RiskList>
            ) : (
              <p>N/A</p>
            )}
          </RiskSection>
        </RiskSectionContainer>
        <Accordion
          theme="default"
          title="Report Preview"
          previewContent={<Report />}
        >
          <Report />
        </Accordion>
      </SectionContent>
    </>
  );
};
